import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../contexts/Auth";
import Button from '../../components/button/button';
import { getDoc, doc } from "firebase/firestore";
import { db } from '../../firebase/index';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [psw, setPsw] = useState('');
    const { login, clientData } = useUserAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(email, psw)
                .then(log =>
                    getDoc(doc(db, `clients/${log.user.uid}`))
                        .then(user => navigate(`/${user.data().store}/admin`)))
        }
        catch (err) {
            alert(err.message)
        }
    }

    return (
        <div style={{ backgroundColor: "#f6f8fa", height: "100vh" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "32px" }}>
                    <h1>Mana<span style={{ color: '#2c25b0' }}>go</span></h1>
                    <h1>Log in</h1>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "320px", padding: "24px", marginTop: "24px", backgroundColor: "#ffffff", border: " 1px solid rgba(0, 0, 0, 0.12)", borderRadius: "8px" }}>
                    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "200px" }}>
                        <TextField
                            id="email"
                            label="Email"
                            type="email"
                            autoComplete="current-email"
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            id="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            onChange={(e) => setPsw(e.target.value)}

                        />
                        <Button id="submitbtn" type="submit" buttonType='fillBtn' buttonLabel='Entrar' />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;