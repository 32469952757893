import { useEffect, useState } from 'react';
import { collection, getDoc, doc, updateDoc, deleteDoc, getDocs, query, where } from "firebase/firestore";
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';
import Select from '../../../components/select/select';
import { db } from '../../../firebase/index';
import { useNotification } from "../../../contexts/Notification";
import { ArrowLeft, Trash } from 'react-feather';
import { useUserAuth } from "../../../contexts/Auth";
import './style.css';

const EditProduct = () => {
    const navigate = useNavigate();
    const { clientData } = useUserAuth();
    const location = useLocation()
    const productId = location.state.id
    const storeId = clientData.store;
    const storeRef = doc(db, 'stores', storeId);
    const [categoriesData, setCategoriesData] = useState(null)
    const [productData, setProductData] = useState(null)
    const prodDocRef = doc(db, `stores/${storeId}/items/`, productId);
    const storeCategoriesRef = collection(storeRef, "categories");
    const { setNotification } = useNotification();


    // Get product
    useEffect(() => {
        const getProductData = async () => {
            const productData = await getDoc(prodDocRef);
            return productData;
        }
        getProductData()
            .then(res => res.data())
            .then(res => setProductData(res))
    }, [])

    // Get categories from store
    useEffect(() => {
        const getCategories = async () => {
            const ctgs = [];
            const querySnapshot = await getDocs(storeCategoriesRef);
            querySnapshot.forEach((doc) => ctgs.push(doc))
            return ctgs;
        }
        getCategories()
            .then(response => setCategoriesData(response))
    }, [])

    const update = async () => {
        await updateDoc(prodDocRef, productData)
            .then(
                setNotification({ open: true, message: 'Produto atualizado com sucesso', severity: 'success' })
            )
            .catch(error => {
                setNotification({ open: true, message: error.message, severity: 'error' })
            });
    };

    const exclude = async () => {
        await deleteDoc(prodDocRef)
            .then(
                setNotification({ open: true, message: 'Produto excluído com sucesso', severity: 'success' })
            )
            .then(navigate(-1))
            .catch(error => {
                setNotification({ open: true, message: error.message, severity: 'error' })
            });
    };

    const handleNameChange = (e) => {
        setProductData(prevState => ({ ...prevState, name: e.target.value }));
    }

    const handleDescriptionChange = (e) => {
        setProductData(prevState => ({ ...prevState, description: e.target.value }));
    }

    const handlePriceChange = (e) => {
        setProductData(prevState => ({ ...prevState, price: e.target.value }));
    }

    const handleServesChange = (e) => {
        setProductData(prevState => ({ ...prevState, serves: e.target.value }));
    }

    const handleIsEnabledChange = () => {
        setProductData(prevState => ({ ...prevState, isEnabled: !productData.isEnabled }));
    }

    const handleCategoryChange = (e) => {
        const newCategory = async () => {
            const newCat = await getDoc(doc(db, `stores/${storeId}/categories/`, e.target.value));
            return newCat
        }
        newCategory()
            .then(cat => cat.data())
            .then(setProductData(prevState => ({ ...prevState, categoryId: e.target.value })))
    }

    return (

        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <div className='headerWithBack'>
                        <Button
                            inlineStyle={{ marginRight: '16px', border: 'none', color: '#333' }}
                            buttonType={'textBtn'}
                            onClick={() => { navigate(-1) }}
                            isIconButton={true}
                            icon={<ArrowLeft />}
                        />
                        <h3 className='pageTitle'>Editar produto</h3>
                    </div>
                    <Button
                        buttonType={'textBtn'}
                        onClick={exclude}
                        icon={<Trash />}
                    />
                </div>
            </div>
            <div className='form-structure'>
                <Input
                    name={'Nome'}
                    type={'text'}
                    onChange={handleNameChange}
                    value={productData && productData.name}
                />
                <Input
                    name={'Descrição'}
                    type={'text'}
                    onChange={handleDescriptionChange}
                    value={productData && productData.description}
                />
                <Input
                    name={'Preço (R$)'}
                    type={'text'}
                    onChange={handlePriceChange}
                    value={productData && productData.price}
                />
                <Input
                    name={'Serve'}
                    type={'text'}
                    onChange={handleServesChange}
                    value={productData && productData.serves}
                />
                <Select
                    data={categoriesData}
                    onChange={handleCategoryChange}
                    placeholder={'Selecione uma categoria'}
                    value={productData && productData.categoryId}
                />
                <label>
                    Disponibilidade
                    <input
                        name="isGoing"
                        type="checkbox"
                        checked={productData && productData.isEnabled}
                        onChange={handleIsEnabledChange} />
                </label>
                <Button
                    buttonType={'outlineBtn'}
                    onClick={update}
                    buttonLabel={'Atualizar'}
                />
            </div>
        </div>

    )

}

export default EditProduct