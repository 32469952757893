import { useEffect, useState } from 'react';
import { collection, getDoc, doc, updateDoc, deleteDoc, getDocs, query, where } from "firebase/firestore";
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';
import { db } from '../../../firebase/index';
import { useNotification } from "../../../contexts/Notification";
import { ArrowLeft, Trash } from 'react-feather';
import { useUserAuth } from "../../../contexts/Auth";
import './style.css';

const EditCategory = () => {
    const navigate = useNavigate();
    const { clientData } = useUserAuth();
    const location = useLocation()
    const categoryId = location.state.id;
    const storeId = clientData.store;
    const [categoryData, setCategoryData] = useState(null);
    const catDocRef = doc(db, `stores/${storeId}/categories/`, categoryId);
    const storeItemsRef = collection(db, `stores/${storeId}/`, 'items');
    const { setNotification } = useNotification();



    // Get category
    useEffect(() => {
        const getCategoryData = async () => {
            const categoryData = await getDoc(catDocRef);
            return categoryData;
        }
        getCategoryData()
            .then(res => res.data())
            .then(data => setCategoryData(data))
    }, [])

    const update = async () => {
        await updateDoc(catDocRef, categoryData)
            .then(
                setNotification({ open: true, message: 'Categoria atualizada com sucesso', severity: 'success' })
            )
            .catch(error => {
                setNotification({ open: true, message: error.message, severity: 'error' })
            });
    };

    const exclude = async () => {
        //do not delete if this category cotains items
        const itms = []
        const q = query(storeItemsRef, where(`categoryId`, "==", categoryId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => itms.push(doc))

        if (itms.length > 0) {
            setNotification({ open: true, message: 'Não é possível deletar categorias que possuam produtos', severity: 'error' })
        }
        else {
            await deleteDoc(catDocRef)
                .then(
                    setNotification({ open: true, message: 'Produto excluído com sucesso', severity: 'success' })
                )
                .then(navigate(-1))
                .catch(error => {
                    setNotification({ open: true, message: error.message, severity: 'error' })
                });
        }
    };

    const handleNameChange = (e) => {
        setCategoryData(prevState => ({ ...prevState, name: e.target.value }));
    }

    return (

        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <div className='headerWithBack'>
                        <Button
                            inlineStyle={{ marginRight: '16px', border: 'none', color: '#333' }}
                            buttonType={'textBtn'}
                            onClick={() => { navigate(-1) }}
                            icon={<ArrowLeft />}
                        />
                        <h3 className='pageTitle'>Editar Categoria</h3>
                    </div>
                    <Button
                        buttonType={'textBtn'}
                        onClick={exclude}
                        icon={<Trash />}
                    />
                </div>
            </div>
            <div className='form-structure'>
                <Input
                    name={'Nome'}
                    type={'text'}
                    onChange={handleNameChange}
                    value={categoryData && categoryData.name}
                />
                <Button
                    buttonType={'outlineBtn'}
                    onClick={update}
                    buttonLabel={'Atualizar'}
                />

            </div>
        </div>

    )

}

export default EditCategory