import './style.css';
import { useNavigate, useOutletContext } from 'react-router-dom';

const Dashboard = () => {

    const activeOptionNavbar = useOutletContext();
    activeOptionNavbar(1)

    return (
        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <h3 className='pageTitle'>Dashboard</h3>
                </div>
            </div>
            <div className='charts-section'>
                <div className='chart'></div>
                <div className='chart'></div>
                <div className='chart'></div>
            </div>

        </div>

    )
}

export default Dashboard