import React, { createContext, useContext, useState } from "react";

export const notificationContext = createContext(null);

export const NotificationContextProvider = ({ children }) => {
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: '' //success, error, warning, info
    })

    const handleClose = () => {
        setNotification({ ...notification, open: false });
    };

    const value = {
        notification,
        setNotification,
        handleClose
    }

    return (
        <notificationContext.Provider value={value}>
            {children}
        </notificationContext.Provider>
    );

};

export const useNotification = () => {
    return useContext(notificationContext);
};
