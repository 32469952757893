import { QRCodeCanvas } from 'qrcode.react';
import html2canvas from 'html2canvas';
import Button from '../../components/button/button';
import { Download } from 'react-feather';
import { useUserAuth } from "../../contexts/Auth";
import './style.css';

const QRCode = () => {
    const host = window.location.origin;
    const { storeData, clientData } = useUserAuth();
    const storeId = clientData.store;

    const downloadQR = event => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.getElementById("print")
        html2canvas(elem)
            .then(canvas => {
                const pngUrl = canvas
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream");
                let downloadLink = document.createElement("a");
                downloadLink.href = pngUrl;
                downloadLink.download = `cardapio.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
    }

    return (

        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <h3 className='pageTitle'>QR Code</h3>
                    <Button
                        buttonType={'outlineBtn'}
                        onClick={downloadQR}
                        buttonText={'Baixar QRCode'}
                        icon={<Download />}
                    />
                </div>
            </div>
            <div style={{ justifyContent: 'center', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div id='print' className='printCard'>
                    <h1>{storeData.name}</h1>
                    <p>Acesse nosso cartápio digital</p>
                    <QRCodeCanvas
                        id="storeCode"
                        value={`${host}/${storeId}/menu`}
                        size={280}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={true}
                    />
                    <h2>Mana<span style={{ color: '#2c25b0' }}>go</span></h2>
                </div>


            </div>

        </div>




    )
}

export default QRCode