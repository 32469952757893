import { useState } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom'
import Input from '../../components/input/input';
import { db } from '../../firebase/index';
import Button from '../../components/button/button';
import { useUserAuth } from "../../contexts/Auth";
import { useNotification } from "../../contexts/Notification";
import './style.css';

const Settings = () => {
    const navigate = useNavigate();
    const { setNotification } = useNotification();
    const { storeData, clientData } = useUserAuth();
    const settingsRef = doc(db, `stores`, clientData.store);
    const [newSettings, setNewSettings] = useState(storeData && storeData)

    const update = async () => {
        await updateDoc(settingsRef, newSettings)
            .then(
                setNotification({ open: true, message: 'Informações atualizadas com sucesso', severity: 'success' })
            )
            .catch(error => {
                setNotification({ open: true, message: error.message, severity: 'error' })
            });
    };

    const handleNameChange = (e) => {
        setNewSettings(prevState => ({ ...prevState, name: e.target.value }));
    }

    const handleAddressChange = (e) => {
        setNewSettings(prevState => ({ ...prevState, address: e.target.value }));
    }

    const handleBnsDaysChange = (e) => {
        setNewSettings(prevState => ({ ...prevState, bnsDays: e.target.value }));
    }

    const handleBnsHoursChange = (e) => {
        setNewSettings(prevState => ({ ...prevState, bnsHours: e.target.value }));
    }

    return (

        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <h3 className='pageTitle'>Configurações</h3>
                </div>
            </div>
            <div className='form-structure'>
                <Input
                    name={'Nome da loja'}
                    type={'text'}
                    onChange={handleNameChange}
                    value={newSettings && newSettings.name}
                />
                <Input
                    name={'Endereço'}
                    type={'text'}
                    onChange={handleAddressChange}
                    value={newSettings && newSettings.address}
                />
                <Input
                    name={'Nome'}
                    type={'text'}
                    onChange={handleBnsDaysChange}
                    value={newSettings && newSettings.bnsDays}
                />
                <Input
                    name={'Horário de Funcionamento'}
                    type={'text'}
                    onChange={handleBnsHoursChange}
                    value={newSettings && newSettings.bnsHours}
                />
                <Button
                    buttonType={'outlineBtn'}
                    onClick={update}
                    buttonLabel={'Atualizar'}
                />
            </div>
        </div>




    )
}

export default Settings