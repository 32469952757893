import React, { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNotification } from "../../contexts/Notification";

const Notification = () => {
    const { notification, handleClose } = useNotification();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={notification.open}
            onClose={handleClose}
            autoHideDuration={3000}
        >
            <Alert severity={notification.severity}>
                {notification.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification