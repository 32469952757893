import { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, query, orderBy } from "firebase/firestore";
import { useNavigate, useOutletContext } from 'react-router-dom';
import './style.css';
import Search from '../../../components/search/search';
import Button from '../../../components/button/button';
import CategoryAdmin from '../../../components/categoryAdmin/categoryAdmin';
import { db } from '../../../firebase/index';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Edit, Plus, Save, X } from 'react-feather';
import { useUserAuth } from "../../../contexts/Auth";

const ListCategories = () => {
    const navigate = useNavigate();
    const { clientData } = useUserAuth();
    const [searchName, setSearchName] = useState('');
    const storeId = clientData.store;
    const storeRef = doc(db, 'stores', storeId);
    const storeCategoriesRef = collection(storeRef, "categories");
    const activeOptionNavbar = useOutletContext();
    const [categoriesData, setCategoriesData] = useState(null);
    const [isReorder, setIsReorder] = useState(false)
    activeOptionNavbar(3)

    const updateSearchName = (name) => {
        setSearchName(name)
    }

    // Get categories from store
    useEffect(() => {
        if (categoriesData === null) {
            const getCategories = async () => {
                const ctgs = [];
                const querySnapshot = await getDocs(query(storeCategoriesRef, orderBy('index')) );
                querySnapshot.forEach((doc) => ctgs.push({
                    data: doc.data(),
                    id: doc.id
                }))
                return ctgs;
            }
            getCategories()
                .then(response => setCategoriesData(response))
        }
    }, [])

    const onDragEnd = (result) => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;
        const newOrder = reorder(
            categoriesData,
            result.source.index,
            result.destination.index
        );
        //store reordered state.
        setCategoriesData(newOrder);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const saveOrder = () => {
        categoriesData.map(async (category, i) => {
            const catDocRef = doc(db, `stores/${storeId}/categories/`, category.id);
            await updateDoc(catDocRef, {index: i})
            .then('foi um')
        })
        .then(setIsReorder(!isReorder))
    }

    return (
        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <h3 className='pageTitle'>Categorias</h3>
                    {
                        isReorder ?
                            <div style={{display: 'flex'}}>
                                <Button
                                    buttonType={'outlineBtn'}
                                    onClick={() => setIsReorder(!isReorder)}
                                    icon={<X/>}
                                />
                                <Button
                                    buttonType={'fillBtn'}
                                    inlineStyle={{marginLeft: '16px'}}
                                    onClick={() => saveOrder()}
                                    icon={<Save/>}
                                    
                                />
                            </div>
                            :
                            <div style={{display: 'flex'}}>

                                <Button
                                    buttonType={'outlineBtn'}
                                    onClick={() => setIsReorder(!isReorder)}
                                    icon={<Edit/>}
                                />
                                <Button
                                    buttonType={'outlineBtn'}
                                    inlineStyle={{marginLeft: '16px'}}
                                    onClick={() => navigate('addNew')}
                                    icon={<Plus/>}
                                />
                            </div>
                    }
                </div>
                <Search onUpdate={updateSearchName} placeholder='Procurar categoria' />

            </div>

            <DragDropContext
                onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-1'>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {
                                categoriesData && categoriesData.map((category, i) =>
                                    searchName ?
                                        category.data.name.includes(searchName) ?

                                            <CategoryAdmin
                                                id={category.id}
                                                key={category.id}
                                                name={category.data.name}
                                                index={i}
                                                isReorder={isReorder}
                                            />
                                            :
                                            null
                                        :
                                        <CategoryAdmin
                                            id={category.id}
                                            key={category.id}
                                            name={category.data.name}
                                            index={i}
                                            isReorder={isReorder}
                                        />
                                )
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div >

    )
}

export default ListCategories