import './App.css';
import { Route, Routes } from "react-router-dom";
import Menu from './pages/menu/menu'
import Login from './pages/login/login'
// import ProductPage from './pages/productPage/productPage'
import Admin from './pages/admin/admin';
import ListProducts from './pages/products/listProducts/listProducts';
import AddProduct from './pages/products/addProduct/addProduct';
import EditProduct from './pages/products/editProduct/editProduct';
import ListCategories from './pages/categories/listCategories/listCategories';
import AddCategory from './pages/categories/addCategory/addCategory';
import EditCategory from './pages/categories/editCategory/editCategory';
import Dashboard from './pages/dashboard/dashboard';
import QRCode from './pages/QRCode/QRCode';
import Settings from './pages/settings/settings';
import { UserAuthContextProvider, useUserAuth } from './contexts/Auth';
import { NotificationContextProvider } from './contexts/Notification';
import { ProtectedRoute } from "./components/protectedRoute";

const App = () => {
  return (
    <UserAuthContextProvider>
      <NotificationContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/:storeId/menu" >
            <Route path="" element={<Menu />} />
            {/* <Route path=":id" element={<ProductPage />} /> */}
          </Route>
          <Route path="/:storeId/admin" element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }>
            <Route
              path=""
              element={<Dashboard />} />
            <Route
              path="products"
              element={<ListProducts />}
            />
            <Route
              path="products/addNew"
              element={<AddProduct />}
            />
            <Route
              path="products/:id"
              element={<EditProduct />}
            />
            <Route
              path="categories"
              element={<ListCategories />}
            />
            <Route
              path="categories/addNew"
              element={<AddCategory />}
            />
            <Route
              path="categories/:id"
              element={<EditCategory />}
            />
            <Route
              path="QRCode"
              element={<QRCode />}
            />
            <Route
              path="settings"
              element={<Settings />}
            />
          </Route>
        </Routes>
      </NotificationContextProvider>
    </UserAuthContextProvider>
  );
}

export default App;