import { useEffect, useState } from "react"
import './style.css'

const Search = (props) => {
    const [text, setText] = useState('')

    useEffect(() => {
        props.onUpdate(text)
    }, [text])

    return (
        <div className="searchBase">
            <div className="searchField">
                <input
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        border: 0,
                        padding: '8px',
                        flexGrow: 1,
                        borderRadius: '8px',
                        fontSize: '14px',
                        lineHeight: '24px'
                    }}
                    type="text" placeholder={`${props.placeholder}`} value={text} onInput={e => setText(e.target.value)} />
            </div>
        </div>

    )

}

export default Search