import { useEffect, useState } from 'react';
import Section from '../../components/section/section';
import Search from '../../components/search/search';
import CategoryFilter from '../../components/categoryFilter/categoryFilter';
import { collection, getDocs, getDoc, doc, query, orderBy } from "firebase/firestore";
import { db } from '../../firebase/index.js';
import { useLocation } from 'react-router-dom'
import './style.css';

const Menu = () => {
    const location = useLocation()
    const path = location.pathname;
    const storeId = path.split('/')[1];
    const [searchName, setSearchName] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [categoriesData, setCategoriesData] = useState(null);
    const [productsData, setProductsData] = useState(null);
    const [storeInfo, setStoreInfo] = useState(null);
    const storeRef = doc(db, 'stores', storeId);
    const storeCategoriesRef = collection(storeRef, "categories");
    const storeProductsRef = collection(storeRef, "items");


    //Get store Info
    useEffect(() => {
        if (storeInfo === null) {
            const getStoreData = async () => {
                const result = await getDoc(storeRef);
                return result.data();
            }

            getStoreData()
                .then(response => setStoreInfo(response))
        }
    }, [])

    // Get categories from store
    useEffect(() => {
        if (categoriesData === null) {
            const getCategories = async () => {
                const ctgs = [];
                const querySnapshot = await getDocs(query(storeCategoriesRef, orderBy('index')) );
                querySnapshot.forEach((doc) => ctgs.push({
                    data: doc.data(),
                    id: doc.id
                }))
                return ctgs;
            }
            getCategories()
                .then(response => setCategoriesData(response))
        }
    }, [])

    // Get products from store
    useEffect(() => {
        if (productsData === null) {
            const getProducts = async () => {
                const products = [];
                const querySnapshot = await getDocs(query(storeProductsRef, orderBy('name')) );
                querySnapshot.forEach((doc) => products.push({
                    data: doc.data(),
                    id: doc.id,
                }))

                return products;
            }
            getProducts()
                .then(response => setProductsData(response))
        }
    }, [])

    const updateSearchName = (name) => {
        setSearchName(name)
    }

    const updateCategoryFilter = (categoryId) => {
        if (categoryFilter === categoryId) {
            setCategoryFilter('')
        }
        else {
            setCategoryFilter(categoryId)
        }
    }

    const checkSectionProducts = (catId) => {
        const filteredResults = productsData.filter(prod => prod.data.categoryId === catId);
        return filteredResults;
    }

    return (
        <>
            <div className='cover'></div>
            <div className='restInfo'>
                <div className='restTopInfo'>
                    <img style={{ height: 64, width: 64 }} src={require('../../assets/images/marca.png')} alt={''} />
                    <div className='restTopRightInfo'>
                        <p className='restName'>{storeInfo ? storeInfo.name : ''}</p>
                        <p className='restAddress'>{storeInfo ? storeInfo.address : ''}</p>
                    </div>
                </div>
                <div className='restBottomInfo'>
                    <p className='restWorking'>{storeInfo ? storeInfo.bnsDays : ''}</p>
                    <p className='restWorking'>{storeInfo ? storeInfo.bnsHours : ''}</p>
                </div>
            </div>

            <div className='menuBase'>

                <Search onUpdate={updateSearchName} placeholder='O que você procura?' />

                <div className='categoryRow'>
                    {
                        categoriesData && categoriesData.map(category =>
                            < CategoryFilter
                                key={category.id}
                                id={category.id}
                                name={category.data.name}
                                state={category.id === categoryFilter ? true : false}
                                filterCategory={updateCategoryFilter}
                                products={checkSectionProducts(category.id)}
                            />
                        )
                    }
                </div>

                <div>
                    {
                        categoriesData && categoriesData.map(section =>
                            categoryFilter ?
                                section.id === categoryFilter ?
                                    < Section
                                        key={section.id}
                                        id={section.id}
                                        name={section.data.name}
                                        search={searchName}
                                        sRef={storeRef}
                                        products={checkSectionProducts(section.id)}
                                    />
                                    :
                                    null
                                :
                                < Section
                                    key={section.id}
                                    id={section.id}
                                    name={section.data.name}
                                    search={searchName}
                                    sRef={storeRef}
                                    products={checkSectionProducts(section.id)}
                                />

                        )
                    }
                </div>
            </div>
        </>

    )
}

export default Menu
