import React from 'react';
import './style.css';


const Button = (props) => {
    const
        {
            buttonType,
            inlineStyle,
            onClick,
            buttonLabel,
            icon,
            isIconButton
        } = props;

    return (
        <button
            className={`${buttonType}`}
            onClick={onClick}
            style={inlineStyle}
            icon={icon}
            isIconButton={isIconButton}
        >
            {icon ?
                <span style={{
                    marginRight: buttonLabel ? '8px' : '0px',
                    height: '24px',
                    width: '24px'
                }}>{icon}</span>
                :
                null
            }
            {
                buttonLabel ?
                    <p className='buttonLabel'>{buttonLabel}</p>
                    :
                    null
            }
        </button>
    )

}

export default Button