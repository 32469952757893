import { useEffect, useState } from 'react';
import { collection, doc, getDocs, addDoc } from "firebase/firestore";
import { db } from '../../../firebase/index.js'
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';
import Select from '../../../components/select/select';
import { ArrowLeft } from 'react-feather';
import { useNotification } from "../../../contexts/Notification";
import { useUserAuth } from "../../../contexts/Auth";
import './style.css';

const AddProduct = () => {
    const navigate = useNavigate();
    const { clientData } = useUserAuth();
    const storeId = clientData.store;
    const storeRef = doc(db, 'stores', storeId);
    const storeProductsRef = collection(storeRef, "items");
    const storeCategoriesRef = collection(storeRef, "categories");
    const [categoriesData, setCategoriesData] = useState(null);
    const [newProduct, setNewProduct] = useState({
        name: '',
        description: '',
        price: '',
        serves: '',
        categoryId: null,
        isEnabled: true
    });
    const { setNotification } = useNotification();


    // Get categories from store
    useEffect(() => {
        const getCategories = async () => {
            const ctgs = [];
            const querySnapshot = await getDocs(storeCategoriesRef);
            querySnapshot.forEach((doc) => ctgs.push(doc))
            return ctgs;
        }
        getCategories()
            .then(response => setCategoriesData(response))
    }, [])

    const handleNameChange = (e) => {
        setNewProduct(prevState => ({ ...prevState, name: e.target.value }));
    }

    const handleDescriptionChange = (e) => {
        setNewProduct(prevState => ({ ...prevState, description: e.target.value }));
    }

    const handlePriceChange = (e) => {
        setNewProduct(prevState => ({ ...prevState, price: e.target.value }));
    }

    const handleServesChange = (e) => {
        setNewProduct(prevState => ({ ...prevState, serves: e.target.value }));
    }

    const handleCategoryChange = (e) => {
        setNewProduct(prevState => ({ ...prevState, categoryId: e.target.value }));
    }

    const clearFields = () => {
        setNewProduct({
            name: '',
            description: '',
            price: '',
            serves: '',
            categoryId: null,
            isEnabled: true
        })
    }


    const register = async () => {
        if (newProduct.name && newProduct.categoryId) {
            await addDoc(storeProductsRef, newProduct)
                .then(
                    () => {
                        setNotification({ open: true, message: 'Produto adicionado com sucesso', severity: 'success' })
                        clearFields()
                    }
                )
                .catch(error => {
                    setNotification({ open: true, message: error.message, severity: 'error' });
                })
        } else {
            setNotification({ open: true, message: 'Nome e categoria necessários', severity: 'error' });
        }
    };



    return (

        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <div className='headerWithBack'>
                        <Button
                            inlineStyle={{ marginRight: '16px', border: 'none', color: '#333' }}
                            buttonType={'textBtn'}
                            onClick={() => { navigate(-1) }}
                            icon={<ArrowLeft />}
                        />
                        <h3 className='pageTitle'>Adicionar produto</h3>
                    </div>
                </div>

            </div>
            <div className='form-structure'>
                <Input
                    name={'Nome'}
                    type={'text'}
                    onChange={handleNameChange}
                    value={newProduct.name}
                />
                <Input
                    name={'Descrição'}
                    type={'text'}
                    onChange={handleDescriptionChange}
                    value={newProduct.description}
                />
                <Input
                    name={'Preço (R$)'}
                    type={'number'}
                    onChange={handlePriceChange}
                    value={newProduct.price}
                />
                <Input
                    name={'Serve'}
                    type={'text'}
                    onChange={handleServesChange}
                    value={newProduct.serves}
                />
                <Select
                    data={categoriesData}
                    onChange={handleCategoryChange}
                    placeholder={'Selecione uma categoria'}
                    value={newProduct.categoryId}
                />
                <Button
                    buttonType={'fillBtn'}
                    onClick={register}
                    buttonLabel={'Adicionar'}
                />
            </div>
        </div>

    )

}

export default AddProduct