import { useEffect } from 'react';
import './style.css';

const Select = (props) => {

    const { data, onChange, placeholder, value } = props;

    return (
        < div className="custom-select" >
            <select onChange={onChange} className="testing">
                {
                    value ?
                        null
                        :
                        <option disabled selected value> {placeholder} </option>
                }
                {
                    data && data.map(option => {
                        if (option.id === value){
                            return <option selected value={`${option.id}`}>{option.data().name}</option>
                        }
                        else{
                            return <option value={`${option.id}`}>{option.data().name}</option>
                        }
                    })
                    
                }
            </select>
        </div >
    )

}

export default Select