import './style.css';
import { useUserAuth } from "../../contexts/Auth";
import Button from '../button/button';
import { LogOut } from 'react-feather';

const AppHeader = (props) => {
    const { isMobile, menuToggle, menuVisible } = props;
    const { clientData, storeData, logout} = useUserAuth();

    const handleLogout = async () => {
        await logout();
    }
    return (
        <div className="header">
            <h1>Mana<span style={{ color: '#2c25b0' }}>go</span></h1>
            {isMobile ?
                menuVisible ?
                    <Button
                        buttonType={'textBtn'}
                        onClick={() => menuToggle(false)}
                        buttonLabel={'Fechar'}
                    />
                    :
                    <Button
                        buttonType={'textBtn'}
                        onClick={() => menuToggle(true)}
                        buttonLabel={'Menu'}
                    />
                :
                <div className='headerRight'>
                    <div className='userInfo'>
                        <p className="userName">{clientData ? clientData.name : null}</p>
                        <p className="store">{storeData ? storeData.name : null}</p>
                    </div>
                    <Button
                        buttonType={'textBtn'}
                        onClick={handleLogout}
                        icon={<LogOut />}
                    />

                </div>
            }

        </div>
    )
}

export default AppHeader