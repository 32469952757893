import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from 'firebase/auth';
import { auth } from '../firebase/index.js';
import { getDoc, doc} from "firebase/firestore";
import { db } from '../firebase/index';
import { useNavigate } from "react-router-dom";

const userAuthContext = createContext();

export const UserAuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [storeData, setStoreData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userData) => {
      if (userData) {
        setUser(userData);
      }
      else{
      }
    })
    return () => { unsubscribe() }
  }, [])

  //Client
  useEffect(() => {
    if (user) {
      const getClientData = async () => {
        const client = await getDoc(doc(db, `clients/${user.uid}`));
        return client.data()
      }
      getClientData()
        .then(res => setClientData(res))
    }
  }, [user])

    //Client
    useEffect(() => {
      if (clientData) {
        const getStoreData = async () => {
          const store = await getDoc(doc(db, `stores/${clientData.store}`));
          return (store.data())
        }
        getStoreData()
          .then(res => setStoreData(res))
      }
    }, [clientData])

  const logout = async () =>  {
    return signOut(auth).then(() => {
      navigate('/')
    })
  }

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const value = {
    user,
    clientData,
    storeData,
    login,
    logout
  }


  return (
    <userAuthContext.Provider value={value}>
      {children}
    </userAuthContext.Provider>
  );
};

export const useUserAuth = () => {
  return useContext(userAuthContext);
};

