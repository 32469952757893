// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBD-0hJfjXMGTypAei3-qpifDHYdl59kO8",
  authDomain: "menu-78ae8.firebaseapp.com",
  projectId: "menu-78ae8",
  storageBucket: "menu-78ae8.appspot.com",
  messagingSenderId: "153134298282",
  appId: "1:153134298282:web:27b499226144d983c10cd3",
  measurementId: "G-RTZS8Q8PBN"
};

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp)

export {db, auth} 