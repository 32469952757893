import './style.css';
import { Link } from 'react-router-dom'

const Item = (props) => {
    return (
        // <Link className="normalize" to={`${props.id}`} state={{ id: props.id }}>
            <div className="itemCard">
                {/* <img className="itemImage" src={require('../../assets/images/item.png')} alt="Image" /> */}
                <div className="info">
                    <div className="topInfo">
                        <p className='itemTitle'>{props.name}</p>
                        <p className='description'>{props.description}</p>
                    </div>

                    <div className="bottomInfo">
                        <p className='price'>R$ {props.price}</p>
                        {props.serves ? <p className='people'>serve {props.serves}</p> : null}
                    </div>

                </div>
            </div>
        // </Link>

    )
}

export default Item;