import { useEffect, useState } from 'react';
import { collection, doc, addDoc, getDocs } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';
import { db } from '../../../firebase/index';
import { useNotification } from "../../../contexts/Notification";
import { ArrowLeft } from 'react-feather';
import { useUserAuth } from "../../../contexts/Auth";
import './style.css';

const AddCategory = () => {
    const navigate = useNavigate();
    const { clientData } = useUserAuth();
    const storeId = clientData.store;
    const storeRef = doc(db, 'stores', storeId);
    const storeCategoriesRef = collection(storeRef, "categories");
    const { setNotification } = useNotification();
    const [newCategory, setNewCategory] = useState({
        name: '',
        index: null
    });

    // Get items from store

    useEffect(() => {
        if (newCategory.index == null) {
            const getCategories = async () => {
                const categs = [];
                const querySnapshot = await getDocs(storeCategoriesRef);
                querySnapshot.forEach((doc) => categs.push(doc));
                return categs;
            }
            getCategories()
                .then(response => setNewCategory(prevState => ({ ...prevState, index: response.length })))
        }
    }, [newCategory.index])

    const handleNameChange = (e) => {
        setNewCategory(prevState => ({ ...prevState, name: e.target.value }));
    }

    const clearFields = () => {
        setNewCategory({ name: '', index: null })
    }

    const register = async () => {
        if (newCategory.name) {
            await addDoc(storeCategoriesRef, newCategory)
                .then(
                    () => {
                        setNotification({ open: true, message: 'Categoria adicionada com sucesso', severity: 'success' })
                        clearFields()
                    }
                )
                .catch(error => {
                    setNotification({ open: true, message: error.message, severity: 'error' });
                })
        }
        else (
            setNotification({ open: true, message: 'Nome não informado', severity: 'error' })
        )
    };


    return (

        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <div className='headerWithBack'>
                        <Button
                            inlineStyle={{ marginRight: '16px', border: 'none', color: '#333' }}
                            buttonType={'textBtn'}
                            onClick={() => { navigate(-1) }}
                            icon={<ArrowLeft />}
                        />
                        <h3 className='pageTitle'>Adicionar Categoria</h3>
                    </div>
                </div>

            </div>
            <div className='form-structure'>
                <Input
                    name={'Nome'}
                    type={'text'}
                    onChange={handleNameChange}
                    value={newCategory.name}
                />
                <Button
                    buttonType={'fillBtn'}
                    onClick={register}
                    buttonLabel={'Adicionar'}
                />
            </div>
        </div>

    )

}

export default AddCategory