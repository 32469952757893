import './style.css';
import { Link } from 'react-router-dom'

const ProductAdmin = (props) => {
    const {key, id, name, category, price, isEnabled} = props;

    return (
        <Link className="itemAdmin" to={`${id}`} state={{ id: id }}>
            {/* <img className="itemAdmin-Image" src={require('../../assets/images/item.png')} alt="Image" /> */}
            <div className="itemAdmin-details">
                <div className="itemAdmin-details-info">
                    <p className='itemAdmin-details-info-category'>{category}</p>
                    <p className='itemAdmin-details-info-title'>{name}</p>
                </div>

                <div className="itemAdmin-details-settings">
                    <p className='itemAdmin-details-settings-price'>R$ {price}</p>
                    <div className='itemAdmin-details-settings-activityStatus'>{isEnabled ? 'disponível' : 'indisponível'}</div>
                </div>
            </div>
        </Link>

    )
}

export default ProductAdmin;