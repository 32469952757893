import { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/navbar';
import AppHeader from "../../components/appHeader/appHeader";
import { Outlet } from "react-router-dom";
import Notification from '../../components/notification/notification';
import './style.css';

const Admin = () => {
    const [activeNavbar, setActiveNavbar] = useState(null)
    const [isMobile, setIsMobile] = useState(null)
    const [menuVisible, setMenuVisible] = useState(null)
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        handleResize()
    })

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const updateNavbar = (navbarId) => {
        if (activeNavbar !== navbarId) {
            setActiveNavbar(navbarId)
        }
    }

    return (
        <div className='structure'>
            <AppHeader
                isMobile={isMobile}
                menuToggle={setMenuVisible}
                menuVisible={menuVisible}
            />
            <div className='wrapper'>
                <Navbar
                    updateNavbar={updateNavbar}
                    activeNavbar={activeNavbar}
                    isMobile={isMobile}
                    menuVisible={menuVisible}
                    menuToggle={setMenuVisible}

                />
                <Outlet
                    context={updateNavbar}
                />
            </div>
            <Notification />
        </div>

    )
}

export default Admin