import { useEffect, useState } from 'react';
import { collection, getDocs, doc, query, orderBy } from "firebase/firestore";
import { useNavigate, useOutletContext } from 'react-router-dom';
import './style.css';
import ProductAdmin from '../../../components/productAdmin/productAdmin';
import Search from '../../../components/search/search';
import Button from '../../../components/button/button';
import { Plus } from 'react-feather';
import { db } from '../../../firebase/index';
import { useUserAuth } from "../../../contexts/Auth";

const ListProducts = () => {
    const navigate = useNavigate();
    const { clientData } = useUserAuth();
    const storeId = clientData.store
    const storeRef = doc(db, 'stores', storeId);
    const storeItemsRef = collection(storeRef, "items");
    const [searchName, setSearchName] = useState('');
    const [categoriesData, setCategoriesData] = useState(null)
    const storeCategoriesRef = collection(storeRef, "categories");


    const activeOptionNavbar = useOutletContext();
    activeOptionNavbar(2)


    const [itemsData, setItemsData] = useState(null);

    const updateSearchName = (name) => {
        setSearchName(name)
    }

    // Get categories from store
    useEffect(() => {
        const getCategories = async () => {
            const ctgs = [];
            const querySnapshot = await getDocs(storeCategoriesRef);
            querySnapshot.forEach((doc) => ctgs.push(doc))
            return ctgs;
        }
        getCategories()
            .then(response => setCategoriesData(response))
    }, [])

    // Get items from store
    useEffect(() => {
        if (itemsData === null) {
            const getItems = async () => {
                const itms = [];
                const querySnapshot = await getDocs(query(storeItemsRef, orderBy('name')));
                querySnapshot.forEach((doc) => itms.push({
                    data: doc.data(),
                    id: doc.id,
                }))

                return itms;
            }
            getItems()
                .then(response => setItemsData(response))
        }
    }, [itemsData])

    const checkCatName = (itemData) => {
        const findCategory = categoriesData.find((obj) => {
            return obj.id === itemData.categoryId;
        });
        return (findCategory.data().name)
    }

    return (
        <div className='pageBase'>
            <div className='headerSection'>
                <div className='headerSection-top'>
                    <h3 className='pageTitle'>Produtos</h3>
                    <Button
                        buttonType={'outlineBtn'}
                        onClick={() => navigate('addNew')}
                        buttonText={'Adicionar produto'}
                        isIconButton={true}
                        icon={<Plus />}
                    />
                </div>
                <Search onUpdate={updateSearchName} placeholder='Procurar produto' />

            </div>
            {
                itemsData && itemsData.map(item =>
                    searchName ?
                        item.data.name.includes(searchName) ?
                            <ProductAdmin
                                key={item.id}
                                id={item.id}
                                name={item.data.name}
                                category={checkCatName(item.data)}
                                price={item.data.price}
                                isEnabled={item.data.isEnabled}
                            />
                            :
                            null
                        :
                        <ProductAdmin
                            key={item.id}
                            id={item.id}
                            name={item.data.name}
                            category={checkCatName(item.data)}
                            price={item.data.price}
                            isEnabled={item.data.isEnabled}
                        />
                )

            }

        </div>

    )
}

export default ListProducts