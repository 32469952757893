import './style.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, PieChart, Columns, Maximize, LogOut, Settings } from 'react-feather';
import Button from '../button/button';
import { useUserAuth } from "../../contexts/Auth";

const Navbar = (props) => {
    const { isMobile, menuVisible, menuToggle, activeNavbar, updateNavbar } = props;
    const navigate = useNavigate();
    const { logout } = useUserAuth();
    const navbarOptions = [
        { id: 1, name: 'Dashboard', path: '', icon: <PieChart /> },
        { id: 2, name: 'Produtos', path: 'products', icon: <List /> },
        { id: 3, name: 'Categorias', path: 'categories', icon: <Columns /> },
        { id: 4, name: 'QRCode', path: 'QRCode', icon: <Maximize /> },
        { id: 5, name: 'Configurações', path: 'settings', icon: <Settings /> },
    ]

    const checkControl = (id, path) => {
        updateNavbar(id)
        navigate(`${path}`)
        menuToggle(false)
    }

    const handleLogout = async () => {
        await logout();
    }

    return (
        <nav className='navBase'
            style={isMobile ?
                {
                    width: '100%',
                    height: 'inherit',
                    position: 'absolute',
                    zIndex: '1',
                    display: menuVisible ? 'block' : 'none'
                }
                :
                {
                    position: 'relative',
                    width: '240px'
                }}>
            <ul className='optionsList'>
                {navbarOptions.map(option =>

                    <li key={option.id} >
                        <button className={option.id === activeNavbar ? 'navbarOptionActive' : 'navbarOption'} onClick={() => { checkControl(option.id, option.path) }}>
                            {option.icon}
                            {option.name}
                        </button>
                    </li>
                )}
            </ul>
            {isMobile ?
                <div style={{ padding: '8px' }}>
                    <Button
                        buttonType={'textBtn'}
                        onClick={handleLogout}
                        buttonLabel={'Sair'}
                        icon={<LogOut />}
                    />
                </div>
                :
                null
            }

        </nav >
    );
}


export default Navbar