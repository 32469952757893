import { useEffect, useState } from 'react';
import './style.css'

const CategoryFilter = (props) => {
    const [isActive, setIsActive] = useState(props.state)

    const checkControl = () => {
        props.filterCategory(props.id)
    }
    const products = props.products;

    useEffect(() => {
        setIsActive(props.state)
    },[props.state])

    return (
        <>
            {products && products.length > 0 ?
                <div style={{
                    backgroundColor: isActive ? '#54886B' : 'transparent',
                    color: isActive ? 'white' : '#333',
                    padding: '8px 12px',
                    border: 'solid 1px #54886B',
                    borderRadius: '16px'
                }} onClick={() => checkControl()}>
                    <div>
                        <p className="categoryName">{props.name}</p>
                    </div>
                </div>
                : null
            }
        </>

    )

}

export default CategoryFilter