import { useEffect, useState } from 'react';
import Item from "../item/item"
import './style.css'

const Section = (props) => {
    const { name, search, products } = props;
    const [isFound, setIsFound] = useState(null);

    useEffect(() => {
        const test = products.some(element => {
            if (element.data.name.toUpperCase().includes(search.toUpperCase()) && element.data.isEnabled === true) {
                return (true);
            }
            else {
                return (false);
            }
        })
        setIsFound(test)
    }, [search])


    return (
        <>
            {
                products && products.length > 0 && isFound ?
                    <p className="sectionTitle">{name}</p>
                    :
                    null
            }

            {
                products && products.map(item =>
                    search ?
                        item.data.name.toUpperCase().includes(search.toUpperCase()) && item.data.isEnabled ?
                            <>

                                <Item
                                    key={item.id}
                                    id={item.id}
                                    name={item.data.name}
                                    description={item.data.description}
                                    price={item.data.price}
                                    serves={item.data.serves}
                                />
                            </>
                            :
                            null
                        :
                        item.data.isEnabled ?
                            <Item
                                key={item.id}
                                id={item.id}
                                name={item.data.name}
                                description={item.data.description}
                                price={item.data.price}
                                serves={item.data.serves}
                            />
                            : null
                )
            }

        </>
    )
}




export default Section