import './style.css';
import { Link, useParams } from 'react-router-dom'
import { Draggable } from "react-beautiful-dnd";
import { DragIndicator } from '@material-ui/icons';

const CategoryAdmin = (props) => {
    const { id, name, index, isReorder } = props;

    return (
        <>
            {isReorder ?
                <Draggable 
                draggableId={id}
                index={index}
                type="TASK"
                >
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}  
                        >
                            <div className="categoryItemAdmin" to={id} state={{ id: id }} style={{boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'}}>
                                <div className="categoryItemAdmin-details">
                                    <div className="categoryItemAdmin-details-info">
                                        <DragIndicator style={{marginRight:'16px'}}/>
                                        <p className='categoryItemAdmin-details-info-title'>{name}</p>
                                    </div>
                                    {/* <div className='categoryItemsList'></div> */}
                                    {/* <div classame="categoryItemAdmin-details-settings"><div className='categoryItemAdmin-details-settings-activityStatus'>disponível</div></div> */}
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
                :
                <Link className="categoryItemAdmin" to={id} state={{ id: id }}>
                    <div className="categoryItemAdmin-details">
                        <div className="categoryItemAdmin-details-info">
                            <p className='categoryItemAdmin-details-info-title'>{name}</p>
                        </div>
                        {/* <div className='categoryItemsList'></div> */}
                        {/* <div className="categoryItemAdmin-details-settings"><div className='categoryItemAdmin-details-settings-activityStatus'>disponível</div></div> */}
                    </div>
                </Link>
            }
        </>


    )
}

export default CategoryAdmin;