
import { useEffect, useState } from 'react';
import './style.css';

const Input = (props) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (props.value !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    },[props.value])

    return (
        < div id="float-label" >
            <input
                type={`${props.type}`}
                value={props.value}
                onChange={ props.onChange} />

            <label
                className={isActive ? "Active" : ""}
                htmlFor="email" >
                {props.name}
            </label>
        </div >
    )

}

export default Input